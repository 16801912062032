import React, { useState } from 'react';
import {
  Button,
  TextField,
  Typography,
  Box,
  Link,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from '@mui/material';
import {
  Info,
  MailOutline,
  PersonOutline,
  Smartphone,
  Visibility,
  VisibilityOff,
  LocalOffer,
} from '@mui/icons-material';
import { toast } from 'react-toastify';
import { doSignUp } from '../helpers/api';

const SignUpComponent: React.FC = () => {
  const initialFormData = {
    firstName: '',
    lastName: '',
    email: '',
    mobileNo: '',
    password: '',
    confirmPassword: '',
    promoCode: '',
    termsOfService: false,
    marketing: false,
  };
  const [formData, setFormData] = useState(initialFormData);
  const [showVerifyEmail, setShowVerifyEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsLoading(true);
    const response = await doSignUp(formData);

    setIsLoading(false);
    if (response.error) toast.error(response.error);
    else {
      setShowVerifyEmail(true);
    }
  };

  const canSubmit = Object.entries(formData).every(([key, value]) => value);

  return (
    <>
      {!showVerifyEmail && (
        <form onSubmit={handleSubmit}>
          <Box sx={{ paddingY: 2 }}>
            {/* <Box sx={{ textAlign: 'center' }}>
          <img
            src={require('../assets/full-logo.png')}
            width={'250px'}
            alt={'Logo'}
          />
          <Typography
            color={'#FFFFFF'}
            fontSize={'30px'}
            fontWeight={'bold'}
            style={{ margin: 8 }}
          >
            Get Started
          </Typography>
        </Box> */}

            <Box
              sx={{
                backgroundColor: '#ffffff',
                padding: 2,
                borderRadius: 2,
                boxShadow: 3,
              }}
            >
              <Typography
                variant='h5'
                gutterBottom
                align='center'
                fontWeight='bold'
              >
                Sign Up
              </Typography>
              <TextField
                label='Enter First Name'
                variant='outlined'
                fullWidth
                margin='dense'
                type='text'
                value={formData.firstName}
                onChange={(e) =>
                  setFormData({ ...formData, firstName: e.target.value })
                }
                InputProps={{
                  style: {
                    paddingRight: '0px',
                  },
                  endAdornment: (
                    <PersonOutline
                      sx={{
                        position: 'absolute',
                        right: '20px',
                      }}
                    />
                  ),
                }}
              />
              <TextField
                label='Enter Last Name'
                variant='outlined'
                fullWidth
                margin='dense'
                type='text'
                value={formData.lastName}
                onChange={(e) =>
                  setFormData({ ...formData, lastName: e.target.value })
                }
                InputProps={{
                  style: {
                    paddingRight: '0px',
                  },
                  endAdornment: (
                    <PersonOutline
                      sx={{
                        position: 'absolute',
                        right: '20px',
                      }}
                    />
                  ),
                }}
              />
              <TextField
                label='Enter Email'
                variant='outlined'
                fullWidth
                margin='dense'
                type='email'
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                InputProps={{
                  style: {
                    paddingRight: '0px',
                  },
                  endAdornment: (
                    <MailOutline
                      sx={{
                        position: 'absolute',
                        right: '20px',
                      }}
                    />
                  ),
                }}
              />
              <TextField
                label='Enter Mobile Number'
                variant='outlined'
                fullWidth
                margin='dense'
                type='text'
                value={formData.mobileNo}
                onChange={(e) =>
                  setFormData({ ...formData, mobileNo: e.target.value })
                }
                InputProps={{
                  style: {
                    paddingRight: '0px',
                  },
                  endAdornment: (
                    <Smartphone
                      sx={{
                        position: 'absolute',
                        right: '20px',
                      }}
                    />
                  ),
                }}
              />
              <TextField
                label='Enter Password'
                variant='outlined'
                fullWidth
                margin='dense'
                type={showPassword ? 'text' : 'password'}
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                InputProps={{
                  style: {
                    paddingRight: '0px',
                  },
                  endAdornment: (
                    <Button
                      sx={{
                        position: 'absolute',
                        right: '0px',
                      }}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </Button>
                  ),
                }}
              />
              <TextField
                label='Confirm Password'
                variant='outlined'
                fullWidth
                margin='dense'
                type={showPassword ? 'text' : 'password'}
                value={formData.confirmPassword}
                onChange={(e) =>
                  setFormData({ ...formData, confirmPassword: e.target.value })
                }
                InputProps={{
                  style: {
                    paddingRight: '0px',
                  },
                  endAdornment: (
                    <Button
                      sx={{
                        position: 'absolute',
                        right: '0px',
                      }}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </Button>
                  ),
                }}
              />
              <TextField
                label='Promo Code'
                variant='outlined'
                fullWidth
                margin='dense'
                type='text'
                value={formData.promoCode}
                onChange={(e) =>
                  setFormData({ ...formData, promoCode: e.target.value })
                }
                InputProps={{
                  style: {
                    paddingRight: '0px',
                  },
                  endAdornment: (
                    <LocalOffer
                      sx={{
                        position: 'absolute',
                        right: '20px',
                      }}
                    />
                  ),
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name='termsOfService'
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        termsOfService: e.target.checked,
                      })
                    }
                  />
                }
                label={
                  <Typography variant='body2'>
                    I agree to the{' '}
                    <a
                      target='_blank'
                      href='https://www.askradar.ai/termsofservice'
                      rel='noreferrer'
                    >
                      Terms of Service
                    </a>{' '}
                    for AskRadar.ai.
                  </Typography>
                }
              />
              <FormControlLabel
                sx={{ alignItems: 'flex-start' }}
                control={
                  <Checkbox
                    name='marketing'
                    sx={{ padding: '0 9px' }}
                    onChange={(e) =>
                      setFormData({ ...formData, marketing: e.target.checked })
                    }
                  />
                }
                label={
                  <Typography variant='body2'>
                    By checking here you agree to receive SMS from Responsium,
                    Inc.
                    <Tooltip
                      disableFocusListener
                      disableTouchListener
                      title={
                        <Typography variant='caption'>
                          By checking here you agree to receive up to four
                          marketing or transactional messages a month via SMS
                          from Responsium, Inc. Messages and data rates may
                          apply. You can reply “STOP” to mobile messages at any
                          time to unsubscribe or reply “HELP” for help. Check
                          out our{' '}
                          <a
                            href='https://www.askradar.ai/privacypolicy'
                            target='_blank'
                            rel='noreferrer'
                          >
                            privacy policy
                          </a>{' '}
                          and terms of use for more information.
                        </Typography>
                      }
                    >
                      <Info sx={{ verticalAlign: 'middle' }} />
                    </Tooltip>
                  </Typography>
                }
              />
              {/* <Typography variant='body2' align='center' sx={{ marginTop: 2 }}>
                I agree to the{' '}
                <a
                  target='_blank'
                  href='https://www.askradar.ai/termsofservice'
                  rel='noreferrer'
                >
                  Terms of Service
                </a>{' '}
                for AskRadar.ai.
              </Typography> */}
              <Button
                variant='contained'
                color='primary'
                fullWidth
                sx={{ marginTop: 3 }}
                type='submit'
                disabled={isLoading || !canSubmit}
              >
                {isLoading ? 'Signing Up...' : 'Sign Up'}
              </Button>
              <Typography variant='body2' align='center' sx={{ marginTop: 2 }}>
                Already signed in?{' '}
                <Link href='/login' underline='hover'>
                  Log in
                </Link>
              </Typography>
            </Box>
          </Box>
        </form>
      )}
      {showVerifyEmail && (
        <Box
          sx={{
            backgroundColor: '#ffffff',
            padding: 3,
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
          <Typography
            variant='h5'
            gutterBottom
            align='center'
            fontWeight='bold'
          >
            Check you Email
          </Typography>
          <p>
            We have sent a message to <b>{formData.email}</b> with a link to
            activate your account
          </p>
          <p>
            <b> Didn't get an email? Check your spam folder</b>
          </p>
          <Link
            component='button'
            variant='subtitle2'
            onClick={() => {
              setFormData(initialFormData);
              setShowVerifyEmail(false);
            }}
          >
            Re-enter your email and try again
          </Link>
        </Box>
      )}
    </>
  );
};

export default SignUpComponent;
