import { useEffect } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import { useChannel } from "../helpers/ChannelContext";
import { useUser } from "../helpers/UserContext";
import { doLoadChannels } from "../helpers/api";

interface EngagementDropdownProps {
  onChange: (channel: SelectChangeEvent) => void;
}

const EngagementDropdown: React.FC<EngagementDropdownProps> = ({
  onChange,
}) => {
  const { authToken } = useUser();
  const { subscribedChannels, setChannels } = useChannel();

  const channels = [{ id: "all", name: "All Channels" }].concat(
    subscribedChannels || []
  );

  const fetchChannels = async () => {
    try {
      const response = await doLoadChannels(authToken!, "subscribed", false);
      if (response.error) console.log(response.error);
      else {
        console.log(response.data);
        setChannels("subscribed", response?.data?.channels || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchChannels();
  }, []);
  return (
    <FormControl sx={{ width: "100%" }}>
      <Select
        style={{ height: "40px" }}
        defaultValue={"all"}
        onChange={onChange}
      >
        {channels.map((channel) => {
          return (
            <MenuItem key={channel?.id} value={channel?.id}>
              {channel?.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default EngagementDropdown;
