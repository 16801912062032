type Method = 'GET' | 'POST' | 'PUT' | 'DELETE';

const url = process.env.REACT_APP_API_URL;

export type ApiResponse = {
  error?: string;
  data?: any;
};

export type Attribute = {
  Name: string;
  Value: string;
};

async function doFetch(
  endpoint: string,
  method: Method,
  body: string | null,
  header: object | null
): Promise<ApiResponse> {
  try {
    let response;
    let compiledUrl = `${url}/${endpoint}`;
    if (method === 'GET' && body) compiledUrl += `?${body}`;

    if (method === 'GET') {
      response = await fetch(compiledUrl, {
        method,
        headers: {
          'Content-Type': 'application/json',
          ...header,
        },
      });
    } else {
      console.log(`${url}/${endpoint}`);
      response = await fetch(`${url}/${endpoint}`, {
        method,
        headers: {
          'Content-Type': 'application/json',
          ...header,
        },
        body,
      });
    }

    if (!response.ok)
      return {
        error:
          (await response.text()) ?? 'Something went wrong. Please try again.',
      };

    const result = await response.json();
    if (result.error) return { error: result.error };

    return { data: result };
  } catch (e: any) {
    return { error: e.message ?? 'Something went wrong. Please try again.' };
  }
}

export async function doSignUp({
  firstName,
  lastName,
  email,
  mobileNo,
  password,
  confirmPassword,
  promoCode,
  termsOfService,
  marketing,
}: {
  firstName: string;
  lastName: string;
  email: string;
  mobileNo: string;
  password: string;
  confirmPassword: string;
  promoCode: string;
  termsOfService: boolean;
  marketing: boolean;
}) {
  if (!firstName) return { error: 'First name is required' };

  if (!lastName) return { error: 'Last name is required' };

  if (!email) return { error: 'Email is required' };

  if (!mobileNo) return { error: 'Mobile Number is required' };

  if (!password) return { error: 'Password is required' };

  if (password !== confirmPassword) return { error: 'Passwords do not match' };

  if (!termsOfService) return { error: 'Please accept our terms of service' };

  if (!marketing) return { error: 'Please accept our marketing terms' };

  return await doFetch(
    'signup',
    'POST',
    JSON.stringify({
      email,
      password,
      firstName,
      lastName,
      mobileNo,
      termsOfService,
      marketing,
      promoCode,
    }),
    null
  );
}

export async function doSignUpEnterprise(
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  confirmPassword: string,
  promoCode: string,
  organizationName: string
) {
  if (!promoCode) return { error: 'Enterprise code is required' };

  if (!firstName) return { error: 'First name is required' };

  if (!lastName) return { error: 'Last name is required' };

  if (!organizationName) return { error: 'Organization name is required' };

  if (!email) return { error: 'Email is required' };

  if (!password) return { error: 'Password is required' };

  if (password !== confirmPassword) return { error: 'Passwords do not match' };

  return await doFetch(
    'enterprise-signup',
    'POST',
    JSON.stringify({
      email,
      password,
      firstName,
      lastName,
      promoCode,
      organizationName,
    }),
    null
  );
}

export async function doLogin(email: string, password: string) {
  if (!email) return { error: 'Email is required' };

  if (!password) return { error: 'Password is required' };

  return await doFetch(
    'login',
    'POST',
    JSON.stringify({ email, password }),
    null
  );
}

export async function doVerifyEmail(
  email: string,
  accessToken: string,
  token: string
) {
  if (!email) return { error: "Email is required" };

  if (!accessToken) return { error: "Access token is required" };

  if (!token) return { error: "Token is required" };

  try {
    let response = await fetch(`${url}/verify-user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, accessToken, token }),
    });

    if (!response.ok) {
      let errorMessage = "Something went wrong. Please try again.";
      const responseText = await response.text();
      const responseJson = JSON.parse(responseText);
      const parts = responseJson.split('"message":"');
      if (parts.length > 1) {
        const messagePart = parts[1];
        const message = messagePart.split('","')[0];
        errorMessage = message;
      }
      return {
        error: errorMessage,
      };
    }
    const result = await response.json();
    if (result.error) {
      return { error: result.error };
    }

    return { data: result };
  } catch (e: any) {
    return { error: e.message ?? "Something went wrong. Please try again." };
  }
}

export async function doRefreshLogin(refreshToken: string) {
  if (!refreshToken) return { error: 'Refresh token is required' };

  return await doFetch('login', 'POST', JSON.stringify({ refreshToken }), null);
}

export async function doForgotPassword(email: string) {
  if (!email) return { error: 'Email is required' };

  return await doFetch(
    'forgot-password',
    'POST',
    JSON.stringify({ email }),
    null
  );
}

export async function doUpdateEmailSubscription(
  email: string,
  status: string = 'unsubscribe'
) {
  if (!email) return { error: 'Email is required' };

  return await doFetch(
    'email-subscription',
    'POST',
    JSON.stringify({ email, status }),
    null
  );
}

export async function doResetPassword(
  email: string,
  password: string,
  confirmPassword: string,
  confirmationCode: string
) {
  if (!email) return { error: 'Email is required' };

  if (!password) return { error: 'Password is required' };

  if (password !== confirmPassword) return { error: 'Passwords do not match' };

  if (!confirmationCode) return { error: 'Confirmation code is required' };

  return await doFetch(
    'reset-password',
    'POST',
    JSON.stringify({ email, password, confirmationCode }),
    null
  );
}

export async function doUpdateProfile(
  accessToken: string,
  attributes: Attribute[]
) {
  return await doFetch(
    'update-profile',
    'POST',
    JSON.stringify({ accessToken, attributes }),
    null
  );
}

export async function doLinkSlack(accessToken: string, code: string) {
  console.log('accessToken', accessToken, code);

  return await doFetch(
    'link-slack',
    'POST',
    JSON.stringify({ code, redirect_uri: window.location.origin + '/profile' }),
    {
      Authorization: accessToken,
    }
  );
}

export async function doUnLinkSlack(accessToken: string) {
  console.log('accessToken', accessToken);

  return await doFetch('unlink-slack', 'POST', null, {
    Authorization: accessToken,
  });
}

export async function toggleChannel(
  accessToken: string,
  channel: string,
  type: string
) {
  console.log('accessToken', accessToken);

  return await doFetch(
    'toggle-channel',
    'POST',
    JSON.stringify({ channel, type }),
    {
      Authorization: accessToken,
    }
  );
}

export async function downloadReport(accessToken: string, duration: string) {
  console.log('accessToken', accessToken, duration);

  return await doFetch('web-report', 'POST', JSON.stringify({ duration }), {
    Authorization: accessToken,
  });
}

export async function doGetUploadUrl(accessToken: string) {
  return await doFetch(
    'get-upload-url',
    'POST',
    JSON.stringify({ accessToken }),
    null
  );
}

export async function doLoadDashboardData(
  accessToken: string,
  duration: string = 'week',
  channel: string | null = null
) {
  console.warn('doLoadDashboardData: ', accessToken);
  return await doFetch(
    'dashboard-data',
    'GET',
    `duration=${duration}${channel ? `&channels=${channel}` : ''}`,
    {
      Authorization: accessToken,
    }
  );
}

export async function doLoadDashboardTrendline(
  accessToken: string,
  duration: string = '3month',
  channel: string | null = null
) {
  console.warn('doLoadDashboardData: ', accessToken);
  return await doFetch(
    'trendline-data',
    'GET',
    `duration=${duration}${channel ? `&channels=${channel}` : ''}`,
    {
      Authorization: accessToken,
    }
  );
}

export async function doLoadChannels(
  accessToken: string,
  type: string = 'public',
  includeStats: boolean = false
) {
  return await doFetch(
    'channels-data',
    'GET',
    `type=${type}&includeStats=${includeStats}`,
    {
      Authorization: accessToken,
    }
  );
}

export async function doLoadConversationBreakdown(
  accessToken: string,
  duration: string,
  channels: string | null = null
) {
  return await doFetch(
    'conversation-breakdown-data',
    'GET',
    `duration=${duration}${channels ? `&channels=${channels}` : ''}`,
    {
      Authorization: accessToken,
    }
  );
}

export async function createStripeCustomer(accessToken: string) {
  console.log('accessToken', accessToken);

  return await doFetch('create-customer', 'POST', null, {
    Authorization: accessToken,
  });
}

export async function initiateSubscription(
  accessToken: string,
  type: string = 'basic',
  paymentMethod: any
) {
  console.log('accessToken', accessToken, type, paymentMethod);

  return await doFetch(
    'purchase-subscription',
    'POST',
    JSON.stringify({ type, paymentMethod }),
    {
      Authorization: accessToken,
    }
  );
}

export async function cancelSubscription(accessToken: string) {
  console.log('accessToken', accessToken);

  return await doFetch('cancel-subscription', 'POST', null, {
    Authorization: accessToken,
  });
}

export async function getPaymentMethods(accessToken: string) {
  console.log('accessToken', accessToken);

  return await doFetch('payment-method', 'GET', null, {
    Authorization: accessToken,
  });
}

export async function deletePaymentMethodApi(
  accessToken: string,
  paymentMethod: string
) {
  console.log('accessToken', accessToken);

  return await doFetch(
    'payment-method',
    'DELETE',
    JSON.stringify({ paymentMethod }),
    {
      Authorization: accessToken,
    }
  );
}

export async function changeDefaultPaymentMethod(
  accessToken: string,
  paymentMethod: string
) {
  console.log('accessToken', accessToken);

  return await doFetch(
    'payment-method',
    'PUT',
    JSON.stringify({ paymentMethod }),
    {
      Authorization: accessToken,
    }
  );
}

export async function addNewCard(accessToken: string, paymentMethod: any) {
  return await doFetch(
    'payment-method',
    'POST',
    JSON.stringify({ paymentMethod }),
    {
      Authorization: accessToken,
    }
  );
}

export async function getUserAttributes(accessToken: string) {
  console.log('accessToken', accessToken);

  return await doFetch('user-attributes', 'GET', null, {
    Authorization: accessToken,
  });
}

export async function getPaymentLink(accessToken: string) {
  console.log('accessToken', accessToken);

  return await doFetch('payment-link', 'GET', null, {
    Authorization: accessToken,
  });
}
