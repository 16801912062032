import React, { useEffect, useState } from 'react';
import AppHeader from './AppHeader';
import NavBar from './NavBar';
import ContentArea from './ContentArea';
import { Box, Grid } from '@mui/material';
import './DashboardComponent.css';
import { Route, Routes } from 'react-router-dom';
import EngagementDashboard from './EngagementDashboard';
import ManageReportingPage from '../pages/ManageReportingPage';
import ManageChannelsPage from '../pages/ManageChannelsPage';
import ConversationBreakdownPage from '../pages/ConversationBreakdownPage';
import PrivateRoute from '../helpers/PrivateRoute';
import OnboardingModal from './OnboardingModal';
import { useUser } from '../helpers/UserContext';
import {
  getPaymentLink,
  getUserAttributes,
} from '../helpers/api';
import { useChannel } from '../helpers/ChannelContext';
import ErrorHeader from './ErrorHeader';
import { useTourContext } from '../helpers/TourContext';
import { usePayment } from '../helpers/PaymentContext';
import ConnectWorkspaceModal from './ConnectWorkspaceModal';

const DashboardComponent: React.FC = () => {
  const { slackTeamName, subscriptionId, authToken, updateProfile } = useUser();
  const { paymentLink, addPaymentLink } = usePayment();
  const [selected, setSelected] = useState('engagementDashboard');
  // const isSmallScreen = window.innerWidth < 768;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState(false);
  const [isConnectWorkspaceModalOpen, setIsConnectWorkspaceModalOpen] =
    useState(false);


  const {
    updateTourState,
    tourState: { run, tourActive },
  } = useTourContext();

  useEffect(() => {
    if (paymentLink) {
      if (!subscriptionId) {
        if (!run && !tourActive) {
          setIsOnboardingModalOpen(true);
        }
      }
    }
  }, [paymentLink, run]);

  useEffect(() => {
    if (!slackTeamName || slackTeamName === 'none') {
      setIsConnectWorkspaceModalOpen(true);
    }
  }, [slackTeamName]);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    getUserAttributes(authToken!).then((response) => {
      if (response.error) console.log(response.error);
      else {
        console.log('attr', response.data);
        if (response.data?.data) {
          updateProfile(response.data.data);
        }
      }
    });
  }, []);

  useEffect(() => {
    // debugger;
    if (
      !subscriptionId ||
      subscriptionId === 'none' ||
      subscriptionId === 'basic'
    ) {
      getPaymentLink(authToken!).then((response) => {
        if (response.error) console.log(response.error);
        else {
          console.log('paymentLink', response.data);
          if (response.data?.data) {
            addPaymentLink(response.data.data);
          }
        }
      });
    }
  }, []);

  return (
    <Box>
      <Box sx={{ position: 'sticky', top: '0px', zIndex: 1 }}>
        <AppHeader toggleDrawer={toggleDrawer} />
      </Box>

      <Grid container flex={'1'}>
        <Grid
          item
          sx={{
            backgroundColor: 'white',
            // width: "112.9px",
            // flexBasis: "112.9px",
            borderRight: 1,
            borderColor: '#E3E3E3',
            position: 'sticky',
            top: '64px',
            height: 'calc(100vh - 64px)',
          }}
        >
          <NavBar
            isDrawerOpen={isDrawerOpen}
            toggleDrawer={toggleDrawer}
            onSelect={setSelected}
          />
        </Grid>

        <Grid
          item
          xs
          sx={{
            flexGrow: 1,
            overflowY: 'auto',
          }}
          className={
            selected === 'engagementDashboard' ? 'content-area-bg' : ''
          }
        >
          <ErrorHeader />

          {/* <ContentArea selected={selected} /> */}
          <Routes>
            <Route
              path='/'
              element={<PrivateRoute component={EngagementDashboard} />}
            />
            <Route
              path='engagementDashboard'
              element={<PrivateRoute component={EngagementDashboard} />}
            />
            <Route
              path='manage-reporting'
              element={<PrivateRoute component={ManageReportingPage} />}
            />
            <Route
              path='manage-channels'
              element={<PrivateRoute component={ManageChannelsPage} />}
            />

            <Route
              path='conversation-breakdown'
              element={<PrivateRoute component={ConversationBreakdownPage} />}
            />
          </Routes>
          {/* <OnboardingModal open={isOnboardingModalOpen} handleClose={() => setIsOnboardingModalOpen(false)} /> */}
          <ConnectWorkspaceModal
            open={isConnectWorkspaceModalOpen}
            handleClose={() => setIsConnectWorkspaceModalOpen(false)}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardComponent;
