import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  Link,
  SvgIcon,
} from '@mui/material';
import {
  Image,
  MailOutline,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUser } from '../helpers/UserContext';
import { doLogin, doRefreshLogin } from '../helpers/api';
import { useScores } from '../helpers/ScoreContext';

const SignInComponent: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login, logout } = useUser();

  // React.useEffect(() => {
  //   if(authToken){
  //     navigate('/dashboard', { replace: true });
  //   }
  // },[]);
  const { weeklyScores, monthlyScores, quarterlyScores } = useScores();

  useEffect(() => {
    console.log('Context values in MyComponent:', {
      weeklyScores,
      monthlyScores,
      quarterlyScores,
    });
  }, []);

  const handleLogin = async () => {
    setIsLoading(true);
    const response = await doLogin(email, password);
    setIsLoading(false);

    if (response.error) toast.error(response.error);
    else {
      login(
        response.data.accessToken,
        response.data.refreshToken,
        response.data.attributes
      );
      if (localStorage.getItem('isUserEmailVerified') === 'false') {
        toast.error('Please verify your email');
        logout();
        navigate('/login');
        return;
      }

      // https://buy.stripe.com/test_8wMaEH5rr3su9TadQQ // Test Payment Link
      // https://buy.stripe.com/dR68xy0NS4Bm0ScdQZ // Live Payment Link
      const isFreeUser = !!(response.data.attributes.find((attr: any) => attr.Name === 'custom:isFreeUser')?.Value === 'true');
      if ( !localStorage.getItem('stripeCustomerId') && !isFreeUser ) {
        window.location.replace(
          `https://buy.stripe.com/dR68xy0NS4Bm0ScdQZ?utm_content=payment_processing&prefilled_email=${email}` // Live
          // `https://buy.stripe.com/test_8wMaEH5rr3su9TadQQ?utm_content=payment_processing&prefilled_email=${email}`
        );
      } else {
        navigate('/dashboard/engagementDashboard', { replace: true });
        toast.success('Logged in successfully');
      }
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: '#ffffff',
        padding: 3,
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Typography variant='h5' gutterBottom align='center' fontWeight='bold'>
        Log in
      </Typography>
      <TextField
        label='Enter Email'
        variant='outlined'
        fullWidth
        margin='normal'
        type='email'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        InputProps={{
          style: {
            paddingRight: '0px',
          },
          endAdornment: (
            <MailOutline
              sx={{
                position: 'absolute',
                right: '20px',
              }}
            />
          ),
        }}
      />
      <TextField
        label='Enter Password'
        variant='outlined'
        fullWidth
        margin='normal'
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        InputProps={{
          style: {
            paddingRight: '0px',
          },
          endAdornment: (
            <Button
              sx={{
                position: 'absolute',
                right: '0px',
              }}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </Button>
          ),
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 2,
        }}
      >
        <FormControlLabel control={<Checkbox />} label='Remember me' />
        <Link href='/forgot-password' variant='body2' underline='hover'>
          Forgot my password
        </Link>
      </Box>
      <Button
        variant='contained'
        color='primary'
        fullWidth
        sx={{ marginTop: 3 }}
        onClick={handleLogin}
        disabled={isLoading}
      >
        {isLoading ? 'Logging In...' : 'Log In'}
      </Button>
      <Typography variant='body2' align='center' sx={{ marginTop: 2 }}>
        Don't have an account?{' '}
        <Link href='/signup' underline='hover'>
          Sign up &gt;
        </Link>
      </Typography>
    </Box>
  );
};

export default SignInComponent;
